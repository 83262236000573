import React,{useContext,useEffect,useState} from 'react';
import Engineering from '../assets/undraw_Engineering_team_a7n2.png'
import EmailNonIt from '../assets/undraw_Email_campaign_re_m6k5.png'
import WorkTime from '../assets/undraw_Work_time_re_hdyv.png'

import { Transit } from 'react-element-transitions'
import AppContext from '../resuable/AppContext';
const Services = () => {

    const {scrollPosition} = useContext(AppContext)
    const [triggerAnimation,setTrigerAnimation] = useState(false)
  
    useEffect(()=>{
      console.log("Analytics",scrollPosition)
      if(!triggerAnimation){
        if(scrollPosition>1000){
          setTrigerAnimation(true)
        }
      }
    },[scrollPosition])
  return (
    <>
    {triggerAnimation ?
     <Transit name={"FADE"} interationCount={1} timing={'linear'} duration={'2'}>
        <div className='w-full py-[10rem] px-4 bg-white'>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
            <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
                <img className='w-20 mx-auto mt-[-3rem] bg-white' src={Engineering} alt="/" />
                <h2 className='text-2xl font-bold text-center py-8'>Recruitment</h2>
                <p className='text-center text-4xl font-bold'>IT & NON IT</p>
                <div className='text-center font-medium'>
                    <p className='py-2 border-b mx-8 mt-8'>Expert Sector Knowledge</p>
                    <p className='py-2 border-b mx-8'>Huge Candidates Network</p>
                    <p className='py-2 border-b mx-8'>Low Commision</p>
                </div>
                <button className='bg-[#48CFCB] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Hire Now</button>
            </div>
            <div className='w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300'>
                <img className='w-20 mx-auto mt-[-3rem] bg-transparent' src={EmailNonIt} alt="/" />
                <h2 className='text-2xl font-bold text-center py-8'> Mobile Applications</h2>
                <p className='text-center text-4xl font-bold'>React Native</p>
                <div className='text-center font-medium'>
                    <p className='py-2 border-b mx-8 mt-8'>Hybrid Apps</p>
                    <p className='py-2 border-b mx-8'>Android & IOS</p>
                    <p className='py-2 border-b mx-8'>Scalable Applications</p>
                </div>
                <button className='bg-black text-[#ffffff] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Build Now</button>
            </div>
            <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
                <img className='w-20 mx-auto mt-[-3rem] bg-white' src={WorkTime} alt="/" />
                <h2 className='text-2xl font-bold text-center py-8'>Web Applications</h2>
                <p className='text-center text-4xl font-bold'>Mern Stack</p>
                <div className='text-center font-medium'>
                    <p className='py-2 border-b mx-8 mt-8'>Scalable Web Apps</p>
                    <p className='py-2 border-b mx-8'>Cloud / Dev-Ops</p>
                    <p className='py-2 border-b mx-8'>Application Deployment & Live</p>
                </div>
                <button className='bg-[#48CFCB] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Start Now</button>
            </div>
        </div>
      </div>
      
      </Transit>
      
      :null
        
    }
   </>
  );
};

export default Services;
