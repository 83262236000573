import React,{useState,useEffect,createContext} from 'react';

import { Transit } from 'react-element-transitions'
import Analytics from './components/Analytics';

import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Newsletter from './components/Newsletter';
import MainHero from './components/MainHero';
import AppContext from './resuable/AppContext';
import Services from './components/Services';

import {db} from './extra/config'
import {collection,addDoc,getDocs} from 'firebase/firestore'

// primary:48CFCB    


function App() {
 
  useEffect(()=>{
    getDocs(collection(db, "webapp-data"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));
                               
                console.log( newData);
            })
  },[])
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
      <AppContext.Provider value={{scrollPosition}}>
           <Navbar /> 
           
            {/* <MainHero/> */}
           
                <Hero />
            
           
                  <Analytics />
          

            <Newsletter />
           
            <Services/>
          
            
            <Footer />
      </AppContext.Provider>
    </div>
  );
}

export default App;
