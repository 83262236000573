import React from 'react'
import LOGO from '../assets/CYNEVO_LOGO-MAIN.png'
import { Transit } from 'react-element-transitions'






function MainHero() {
  return (
            <div className='mainhero'>
                <Transit name="FADE" interationCount={1} duration={'1'} timing={'linear'} >
                <div style={{width:800,height:800,overflow:'hidden'}}>
                <img style={{objectFit:'fill',width:800,height:800}}  src={LOGO} />
                </div>
                
                </Transit>

            </div>
  )
}

export default MainHero