import React,{useEffect, useContext,useState} from 'react';
import Hiring from '../assets/undraw_Hiring_re_yk5n.png';
import { Transit } from 'react-element-transitions'
import AppContext from '../resuable/AppContext';


const Analytics = () => {

  const {scrollPosition} = useContext(AppContext)
  const [triggerAnimation,setTrigerAnimation] = useState(false)

  useEffect(()=>{
    console.log("Analytics",scrollPosition)
    if(!triggerAnimation){
      if(scrollPosition>250){
        setTrigerAnimation(true)
      }
    }
  },[scrollPosition])


  return (
    <div className='w-full bg-white py-16 px-4'>
              { triggerAnimation&& 
        <Transit name={"SLIDE-LEFT"} interationCount={1} timing={'ease-in'} duration={'1'}>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>


        <img className='w-[500px] mx-auto my-4' src={Hiring} alt='/' />
       
        
    
        <div className='flex flex-col justify-center'>
          <p className='text-[#48CFCB] font-bold '>Committed Team, Satisfied Clients & Established Records</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Experience Recruitment Service Like Never Before</h1>
          <p>
          The service offered at Human Resource India is unmatched. Whether you are an existing client or a new client, you are always valued.
          This is what you can expect when you work with us, we are the best placement consultant in India.
          </p>
          <button className='bg-black text-[#fff] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Get Started</button>
        </div>
      </div>
      </Transit>}
    </div>
  );
};

export default Analytics;
