import React, { useState ,useContext,useEffect} from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import LOGO from '../assets/CYNEVO_LOGO-MAIN.png'
import AppContext from '../resuable/AppContext';
import { Transit } from 'react-element-transitions'
const Navbar = () => {

  const [nav, setNav] = useState(false);
  const {scrollPosition} = useContext(AppContext)


  useEffect(()=>{
    // console.log(scrollPosition)
  },[scrollPosition])
  const handleNav = () => {
    setNav(!nav);
  };


  
    return (
   
      <div  className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
        
        {/* <h1 className='w-full text-3xl font-bold text-[#48CFCB]'>CYNEVO</h1> */}
        <Transit name="WIGGLE" interationCount={1} duration={'2'} timing={'ease-in-out'} >
          <img src={LOGO} style={{marginLeft:-30}} width={200} height={100}/>
       </Transit>
        
        
        <ul className='hidden md:flex'>
          <li className='p-4 clickableLink'>Home</li>
          <li className='p-4 clickableLink'>Company</li>
          <li className='p-4 clickableLink'>Resources</li>
          <li className='p-4 clickableLink'>About</li>
          <li className='p-4 clickableLink'>Contact</li>
        </ul>
        <div onClick={handleNav} className='block md:hidden'>
            {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
        </div>
        <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
          {/* <h1 className='w-full text-3xl font-bold text-[#48CFCB] m-4'>CYNEVO</h1> */}
      
            <li className='p-4 border-b border-gray-600'>Home</li>
            <li className='p-4 border-b border-gray-600'>Company</li>
            <li className='p-4 border-b border-gray-600'>Resources</li>
            <li className='p-4 border-b border-gray-600'>About</li>
            <li className='p-4'>Contact</li>
        </ul>
      </div>
    );

  
};

export default Navbar;
