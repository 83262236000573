// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyClHpCY0n1h6F7HQ7cOGTlWswKVUwJ_pDY",
  authDomain: "cynevo-web.firebaseapp.com",
  projectId: "cynevo-web",
  storageBucket: "cynevo-web.appspot.com",
  messagingSenderId: "219655329479",
  appId: "1:219655329479:web:beecf5fe54e4a78aaa50e2",
  measurementId: "G-R7G4WDPYFW"
};

// Initialize Firebase
const FIREBASE_APP = initializeApp(firebaseConfig);
export const db = getFirestore(FIREBASE_APP);





